import React from 'react';
import withPrivateRoute from '../../hocs/withPrivateRoute';
import { Roles } from '../../utils/constants';
import PricesTable from '../../modules/Admin/Prices/PricesTable';

const Prices: React.FC = () => {
	return (
		<div className="page">
			<h1 className="page__title">Prices</h1>

			<PricesTable />
		</div>
	);
};

export default withPrivateRoute(Prices, [Roles.admin]);
