import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Space, Popconfirm } from 'antd';
import EditableTable from '../../../components/tables/EditableTable/EditableTable';
import { IPrice, NEW_PRICE } from '../../../services/api/Prices';
import { pricesStoreContext } from '../../../stores/PricesStore';

const PricesTable: React.FC = () => {
	const { fetchPrices, prices, setPrices, savePrice, deletePrices } = useContext(pricesStoreContext);
	const [selected, setSelected] = useState<number[]>([]);
	const [editingKey, setEditingKey] = useState<number | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		fetchPrices();
	}, []);

	const rowSelection = {
		selected,
		onChange: (keys) => setSelected(keys),
	};

	const onDeleteRows = () => {
		setIsLoading(true);
		deletePrices(selected);
		setIsLoading(false);
		setSelected([]);
	};

	const onSaveRow = async (id, row) => {
		setIsLoading(true);
		await savePrice(id, row);
		setIsLoading(false);
		setEditingKey(null);
	};

	const onEditRow = (rowId) => {
		setEditingKey(rowId);
	};

	const onCancelEditing = () => {
		setEditingKey(null);
	};

	const onAddRow = () => {
		if (editingKey === NEW_PRICE.id) return;

		setPrices([NEW_PRICE, ...prices]);
		setEditingKey(NEW_PRICE.id);
	};

	return (
		<>
			<Space style={{ marginBottom: 16 }}>
				{selected.length === 0 ? (
					<>
						{prices.length < 3 && (
							<Button type="primary" onClick={onAddRow}>
								Add price
							</Button>
						)}
					</>
				) : (
					<Popconfirm title="Are you sure you want to delete this rows?" onConfirm={onDeleteRows}>
						<Button type="primary" danger>
							Delete
						</Button>
					</Popconfirm>
				)}
			</Space>

			<EditableTable<IPrice>
				rowKey="id"
				data={prices}
				saveRow={onSaveRow}
				editRow={onEditRow}
				cancelEditing={onCancelEditing}
				columns={columns}
				rowSelection={rowSelection}
				editingKey={editingKey}
				pagination={false}
				loading={isLoading}
			/>
		</>
	);
};

const columns = [
	{
		title: 'Title',
		dataIndex: 'title',
		key: 'title',
		dataType: 'text',
		sorter: (a, b) => a.title.localeCompare(b.title),
		editable: true,
	},
	{
		title: '1 request cost',
		dataIndex: 'costTip',
		key: 'costTip',
		dataType: 'number',
		sorter: (a, b) => a.costTip - b.costTip,
		ellipsis: true,
		//editable: true,
		render: (_, row) => row.countPaidTips>0 ? (Math.round(100*row.totalPrice/row.countPaidTips)/100) : '-',

	},
	{
		title: 'Count of free requests',
		dataIndex: 'countFreeTips',
		key: 'countFreeTips',
		dataType: 'number',
		sorter: (a, b) => a.countFreeTips - b.countFreeTips,
		ellipsis: true,
		editable: true,
	},
	{
		title: 'Count of paid requests',
		dataIndex: 'countPaidTips',
		key: 'countPaidTips',
		dataType: 'number',
		sorter: (a, b) => a.countPaidTips - b.countPaidTips,
		ellipsis: true,
		editable: true,
	},
	{
		title: 'Total requests',
		dataIndex: 'totalTips',
		key: 'totalTips',
		sorter: (a, b) => a.countFreeTips + a.countPaidTips - b.countFreeTips - b.countPaidTips,
		ellipsis: true,
		render: (_, row) => row.countFreeTips + row.countPaidTips,
	},
	{
		title: 'Total cost',
		dataIndex: 'totalPrice',
		key: 'totalPrice',
		dataType: 'number',
		sorter: (a, b) => a.costTip * a.countPaidTips - b.costTip * b.countPaidTips,
		ellipsis: true,
		render: (_, row) => row.totalPrice,
		editable: true
	},
];

export default observer(PricesTable);
