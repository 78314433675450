import React from 'react';
import { Popconfirm, Form, Typography } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import EditableCell from './EditableCell';
import AppTable from '../AppTable/AppTable';

interface Col<T> extends ColumnProps<T> {
	dataType?: string;
	editable?: boolean;
	onCell?: (record: T) => object;
}

type IdRow = number | string;

type Props<T> = {
	data: T[];
	saveRow: (id: IdRow, row: T) => void;
	editRow: (id: IdRow) => void;
	cancelEditing: () => void;
	editingKey: IdRow | null;
	columns: Col<T>[];
} & TableProps<T>;

const EditableTable = <T extends { id: IdRow }>({
	data,
	saveRow,
	editRow,
	cancelEditing,
	editingKey,
	columns,
	...rest
}: Props<T>) => {
	const [form] = Form.useForm();
	const isEditing = (record: T) => record.id === editingKey;

	const edit = (record: T) => {
		form.setFieldsValue({ ...record });
		editRow(record.id);
	};

	const save = async (id: IdRow) => {
		try {
			const row = await form.validateFields();
			saveRow(id, row);
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

	columns = [
		...columns,
		{
			title: 'Actions',
			dataIndex: 'actions',
			render: (_: any, record: T) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Typography.Link onClick={() => save(record.id)} style={{ marginRight: 8 }}>
							Save
						</Typography.Link>
						<Popconfirm title="Sure to cancel?" onConfirm={cancelEditing}>
							<a>Cancel</a>
						</Popconfirm>
					</span>
				) : (
					<Typography.Link disabled={!!editingKey} onClick={() => edit(record)}>
						Edit
					</Typography.Link>
				);
			},
		},
	];

	const mergedColumns: Col<T>[] = columns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record: T) => ({
				record,
				inputType: col.dataType === 'number' ? 'number' : 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
			}),
		};
	});

	return (
		<Form form={form} component={false}>
			<AppTable
				components={{
					body: {
						cell: EditableCell,
					},
				}}
				dataSource={data}
				columns={mergedColumns}
				rowClassName="editable-row"
				{...rest}
			/>
		</Form>
	);
};

export default EditableTable;
